import Routing from './Routing';
// import './App.module.css';

function App() {
  return (
    <div >
      <Routing />
    </div>
  );
}

export default App;
